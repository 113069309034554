.wallet-card {
  min-height: 10vh;
  max-height: 10vh;
  background: linear-gradient(270deg, #9e1818 0%, #0081e2 100%);
  border-radius: 23px;
  margin: 0 20px;
  .wallet-container {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 20px;
    border-radius: 20px;
    .logout {
      display: flex;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.wallet-btn {
  &__disconnected {
    border-radius: 20px;
    padding: 8px 30px;
  }

  &__connected {
    padding: 0;
    max-height: 40.5px;
    border-radius: 20px !important;
    padding-right: 20px;
    padding-left: 0 !important;
    .left {
      width: 100%;
      max-height: 40.5px;
      min-height: 40.5px;
      display: flex;
      padding: 0.5rem 1.5rem;
      background: var(--bg-main);
      color: #fff;
      border-radius: 20px;
      margin-right: 10px;
      align-items: center;
      gap: 10px;

      img {
        padding: 2px;
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }

    &__container {
      width: 100%;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }
}
.navbar {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: black;
  height: 80px;
  z-index: 20;
  #bees-logo-border {
    fill: #fff;
  }

  &__left {
    display: flex;
    align-items: baseline;
    gap: 20px;
    list-style: none;
    color: var(--text-primary);
    font-weight: 700;
    li {
      .icons {
        align-items: baseline;
        width: 100%;
       
        .link {
          display: flex;
          flex-direction: row;
          justify-content: baseline;
          align-items: baseline;
          margin-left: 40px;
          font-size: 20px;
        }
        img {
          align-self: baseline;
          width: 20px;
          height: 14px;
          margin-left: -50px;
        }
      }
      .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        gap: 5px;
        padding-left: 2vw;
        width: 100%;
        height: 40px;
        max-height: 40px;
        .selected {
          font-size: 20px;
          align-items: baseline;
          justify-content: baseline;
          color: #fff;
          text-decoration: underline;
        }
        a {
          font-size: 25px;
          color: #fff;
          text-decoration: none;
          align-items: baseline;
          justify-content: baseline;
          .link {
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: baseline;
            small {
              font-size: 20px;
            }
          }
        }
      }
      &.logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        img {
          width: 50px;
          height: 30px;
        }
        h1 {
          font-size: 40px;
          color: #fff;
          font-weight: bold;
          // font-family: Teko;
        }
        padding: 5px;
        // img {
        //   width: 200px;
        //   height: 100px;
        // }
      }
      cursor: pointer;
      user-select: none;

      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        color: #fff;
      }
    }
  }

  &__right {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    #discord {
      fill: #5165f6;
    }

    #youtube {
      fill: #f71801;
    }

    #twitter {
      fill: #3da1f2;
    }

    #medium {
      fill: #fff;
    }
  }

  .navbar-right__container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.wallet-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 20px;
  border-radius: 20px;
  .logout {
    display: flex;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.wallet-btn {
  &__disconnected {
    border-radius: 20px;
    padding: 8px 30px;
  }

  &__connected {
    padding: 0;
    max-height: 40.5px;
    border-radius: 20px !important;
    padding-right: 20px;
    padding-left: 0 !important;
    // border: 1px solid var(--bg-main) !important;
    .left {
      width: 100%;
      max-height: 40.5px;
      min-height: 40.5px;
      display: flex;
      padding: 0.5rem 1.5rem;
      background: var(--bg-main);
      border-radius: 20px;
      margin-right: 10px;
      align-items: center;
      gap: 10px;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }

    &__container {
      width: 100%;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: var(--text-primary);
    }
  }

  svg {
    width: 50px;
    height: 50px;
    #bees-logo-border {
      fill: #fff;
    }
  }

  .bg-menu-ico {
    display: none;
  }

  .header-info-container {
    display: flex;
    gap: 20px;

    .seedz {
      display: flex;
      gap: 5px;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 586px) {
  .header {
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
    .wallet-container {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 880px) {
  .navbar {
    &__left {
      li {
        display: none;
      }

      // .logo {
      //   display: block;
      // }
    }
    .navbar-right__container {
      ul {
        display: none;
      }
    }
  }
  .wallet-btn {
    &__disconnected {
      border-radius: 20px;
      padding: 8px 30px;
    }

    &__connected {
      padding: 0;
      max-height: 40.5px;
      border-radius: 20px !important;
      padding-right: 20px;
      padding-left: 0 !important;
      // border: 1px solid var(--bg-main) !important;
      .left {
        width: 100%;
        max-height: 40.5px;
        min-height: 40.5px;
        display: flex;
        padding: 5px;
        background: var(--bg-main);
        border-radius: 20px;
        margin-right: 10px;
        align-items: center;
        gap: 10px;

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }

      &__container {
        width: 100%;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .navbar {
    padding: 0.5rem 1rem 0 2rem;
    &__left {
      li {
        display: none;

        // &.logo {
        //   padding: 5px;
        //   img {
        //     width: 80px;
        //     height: 50px;
        //   }
        //   display: block;
        // }
      }
    }
    .navbar-right__container {
      ul {
        display: none;
      }
    }
  }
  .wallet-btn {
    &__disconnected {
      border-radius: 20px;
      padding: 8px 30px;
    }

    &__connected {
      padding: 0;
      max-height: 40.5px;
      border-radius: 20px !important;
      padding-right: 20px;
      padding-left: 0 !important;
      // border: 1px solid var(--bg-main) !important;
      .left {
        width: 100%;
        max-height: 40.5px;
        min-height: 40.5px;
        display: flex;
        padding: 5px;
        background: var(--bg-main);
        border-radius: 20px;
        margin-right: 10px;
        align-items: center;
        gap: 10px;
        font-size: 10px;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }

      &__container {
        width: 100%;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}
