:root {
  //backgrounds
  --bg-main: #3c1414;
  --bg-card: rgba(9, 15, 33, 0.8);
  --bg-card-secondary: #fff;
  --bg-success: #61c149;

  //texts colors
  --text-main: #fff;
  --text-primary: #d24846;
  --text-secondary: #535353;
  --text-success: #61c149;
  --text-muted: #c4c4c4;
  --text-solid: #000;

  //utils
  --b-radius-card: 5px;
}
