.chasers {
  background-color: var(--bg-secondary);
  margin-top: -6vh;

  label {
    display: inline-block;
    margin: 0 0 -1px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
    font-size: calc(2vw + 1vh);
  }

  input:checked + label {
    color: #e26f2f;
    // border: 1px solid #e26f2f;
    // border-top: 2px solid #e26f2f;
    border-bottom: 1px solid black;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .chasers-title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    text-align: left;
    margin-left: 10vw;
    label {
      cursor: pointer;
    }
    button {
      width: 40%;
      min-width: 2vw;
      height: calc(2vw + 3vh);
      background-color: transparent;
      color: #fff;
      border-style: solid;
      border-color: #fff;
      border-width: 2px;
      font-size: calc(2vw + 0.6vh);
      cursor: pointer;
      &.active {
        color: #e26f2f;
        border-color: #e26f2f;
      }
    }
  }
  .chasers-container {
    display: flex;
    flex-direction: row;
    border-top: none;
    svg {
      color: #fff;
    }
    .filter {
      margin-top: 100px;
      bottom: 0;
      width: 15vw;
      padding-top: 10px;
      min-width: 120px;

      .check-controls {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        align-self: center;
        padding-left: 0.5rem;
        hr {
          opacity: 1;
          border: 1px solid #fff;
        }
        .check-container {
          display: block;
          position: relative;
          padding-left: 20px;
          margin-bottom: 10px;
          font-size: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .button-container {
          display: block;
          position: relative;
          margin-bottom: 10px;
          font-size: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          a {
            border-style: solid;
            border-color: #fff;
            border-width: 1px;
            border-radius: 5px;
            background-color: transparent;
            color: #fff;
            min-width: 80px;
            font-size: 14px;
            padding: 2px 5px;
            svg {
              margin-bottom: 3px;
            }
            &:hover {
              background-color: #fff;
              color: #000;
              border-color: #000;
              svg {
                color: #000;
              }
            }
          }
        }

        /* Hide the browser's default checkbox */
        .check-container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 1rem;
          width: 1rem;
          background-color: transparent;
          border-style: solid;
          border-width: 1px;
          border-color: #fff;
        }

        /* On mouse-over, add a grey background color */
        .check-container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .check-container input:checked ~ .checkmark {
          background-color: transparent;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .check-container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .check-container .checkmark:after {
          left: 5px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        button {
          background-color: transparent;
          color: #fff;
          border-style: none;
          font-size: large;
          cursor: pointer;
        }
        .active {
          color: #e26f2f;
        }
      }
    }
    .chasers-section {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-content: flex-start;
      .search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width: 100%;
        padding: 0 2rem;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 80px;
        background-color: #000;
        z-index: 100;
        .input-container {
          display: flex;
          flex-direction: row;
          width: 25%;
          height: 40px;
          border-radius: 25px;
          border-style: solid;
          border-width: 1px;
          margin: 10px 0;
          z-index: 5;
          svg {
            padding: 10px;
            color: white;
            min-width: 40px;
            min-height: 40px;
            text-align: center;
          }
          input {
            display: block;
            background-color: transparent;
            color: #fff;
            width: 100%;
            min-width: 200px;
            min-height: 40px;
            outline: none;
            border-style: none;
          }
        }
        .controls {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 5px;
          position: -webkit-sticky;
          position: sticky;
          width: 60%;
          button {
            background-color: transparent;
            color: #fff;
            border-style: none;
            font-size: 18px;
            cursor: pointer;
            svg {
              margin-bottom: 4px;
            }
          }
          .active {
            color: #e26f2f;
          }
        }
      }
      .chasers-items {
        padding: 0.5rem;
        display: inline-flex;
        justify-content: center;
        align-content: flex-start;
        width: 80vw;
        min-height: 300px;
        margin: 0;
        flex-wrap: wrap;
        h1 {
          text-align: center;
        }
        .item {
          width: 35%;
          max-width: 300px;
          min-width: 300px;
          min-height: 300px;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 2px;
          border-radius: 10px;
          gap: 10px;
          position: relative;
          .img-cover {
            width: 35%;
            max-width: 300px;
            min-width: 300px;
            min-height: 300px;
            padding: 0.5rem;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .culture-toggle-yes {
              width: 100px;
              height: 34px;
              position: absolute;
              margin-left: 10px;
              text-shadow: 1px 1px 5px black;
            }
            .social-links {
              display: flex;
              flex-direction: row;
              justify-self: end;
              align-self: flex-end;
              z-index: 10;
              font-size: 20px;
              margin-right: 20px;
              margin-top: -5px;
            }
            .ticker {
              display: flex;
              flex-direction: row;
              justify-self: end;
              align-self: flex-end;
              position: absolute;
              z-index: 10;
              font-size: 20px;
              right: 27px;
              margin-top: 5px;
              text-shadow: #000 1px 0 1rem;
            }
            .culture {
              display: flex;
              flex-direction: column;
              justify-content: end;
              -webkit-justify-content: flex-end;
              align-items: flex-end;
              -webkit-align-items: flex-end;
              min-width: 100%;
              height: 100%;
              min-height: 250px;
              cursor: pointer;
              z-index: 5;

              .name {
                display: flex;
                flex-direction: column;
                justify-self: baseline;
                align-self: flex-start;
                z-index: 2;
                margin-left: 5px;
                font-size: 25px;
                font-weight: bold;
                width: 100%;
                text-shadow: 1px 1px 5px black;
                small {
                  font-size: 12px;
                  font-weight: normal;
                }
              }
            }
            .creators {
              display: flex;
              flex-direction: column;
              justify-content: end;
              -webkit-justify-content: flex-end;
              align-items: flex-end;
              -webkit-align-items: flex-end;
              min-width: 100%;
              height: 100%;
              min-height: 250px;
              z-index: 5;

              .name {
                display: flex;
                flex-direction: column;
                justify-self: baseline;
                align-self: flex-start;
                z-index: 2;
                margin-left: 5px;
                font-size: 25px;
                font-weight: bold;
                width: 100%;
                text-shadow: 1px 1px 5px black;
                small {
                  font-size: 12px;
                  font-weight: normal;
                }
              }
            }
          }
          img {
            position: relative;
            width: 100%;
            height: 300px;
            border-radius: 10px;
          }
        }

        .item::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('./../../../assets/shadow.png'); /* Path to your overlay image */
          background-size: cover; /* Adjust the sizing of the overlay image */
          opacity: 0.5; /* Set the desired opacity value (0 to 1) */
          border-radius: 10px; /* Apply the same border radius as the img tag */
          z-index: 1; /* Ensure the overlay image is on top of the img tag */
        }
      }
    }
  }
}
.collection-content {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  padding: 0 0.2rem;
}
.collection-content::-webkit-scrollbar {
  background-color: #000;
  border-radius: 10px;
  width: 5px;
  height: 5px;
}
.collection-content::-webkit-scrollbar-track {
  background: #000;
}
.collection-content::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: #fff;
  border-radius: 10px;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  font-weight: 800;
}

.accordion-title:hover {
  background-color: transparent;
}

.accordion-content {
  padding: 0 0.2rem;
}

.accordion-content {
  background-color: transparent;
}

@media screen and (max-width: 1024px) {
  .chasers {
    margin-top: -4vh;
    .chasers-container {
      .chasers-section {
        .search {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .input-container {
            width: 25%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .chasers {
    margin-top: -4vh;
    .chasers-container {
      .chasers-section {
        .search {
          .input-container {
            width: 55%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .chasers {
    margin-top: -4vh;
    .chasers-container {
      .chasers-section {
        max-width: 275px;
        .chasers-items {
          padding: 0 0 0.5 0;
          max-width: 275px;
          h1 {
            font-size: 18px;
          }
          .item {
            max-width: 250px;
            min-width: 250px;
            min-height: 250px;
            max-height: 250px;

            .img-cover {
              max-width: 250px;
              min-width: 250px;
              min-height: 250px;
              max-height: 250px;
              .culture-toggle-yes {
                width: 100px;
                height: 34px;
                position: absolute;
                margin-left: 10px;
                text-shadow: 1px 1px 5px black;
              }
              .social-links {
                display: flex;
                flex-direction: row;
                justify-self: end;
                align-self: flex-end;
                z-index: 10;
                font-size: 20px;
                margin-right: 20px;
                margin-top: -5px;
              }
              .creators {
                min-height: 200px;
                .name {
                  font-size: 20px;
                }
              }
              .culture {
                min-height: 200px;
                .name {
                  font-size: 20px;
                }
              }
            }
            img {
              position: relative;
              width: 100%;
              height: 250px;
              border-radius: 10px;
            }
          }
        }
      }
      .filter {
        padding: 0 0 0 0.5rem;
        .check-controls {
          top: 100px;
          .check-container {
            font-size: 10px;
          }
        }
      }
      .chasers-section {
        .search {
          margin-top: 30px;
          .controls {
            gap: 2px;
            button {
              font-size: 10px;
              svg {
                margin-bottom: 2px;
              }
            }
          }
          .input-container {
            width: 65%;
          }
        }
      }
    }
  }
  .accordion-content {
    padding: 0 0.5rem;
  }
}
