.chasers {
  color: var(--text-main);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 4rem;
  gap: 10px;
  .main-section {
    display: flex;
    flex-direction: column;
    min-height: 20vh;
    max-height: 20vh;
    min-width: 100%;
    padding-bottom: 20vh;
    position: sticky;
    .title {
      h1 {
        text-align: left;
        font-size: 28px;
        color: #e26f2f;
      }
    }
    .subtitle {
      // padding: 0 5rem;
      text-align: left;
      font-size: larger;
    }
    .twitter-btn {
      cursor: pointer;
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 20px;
      padding: 5px 10px;
      min-width: 90px;
      width: 90px;
      margin: auto;
      font-size: 15px;
      text-decoration: none;
      text-align: center;
    }
  }

  section {
    min-height: 115vh;
    padding-top: 15vh;
    scroll-snap-align: none;
  }
}

@media screen and (max-width: 950px) {
  .home {
    .top-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 800px) {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .home {
    padding: 0 10px;
  }
  .chasers {
    padding: 0 0.5rem;
    .main-section {
      .subtitle {
        font-size: medium;
        padding: 0 0 0 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}
@media screen and (max-width: 1224px) {
  .home {
    .wallet-container {
      padding: 0;
      font-size: small !important;
      margin: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    padding: 0;
  }
}
