.bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  .test {
    background-color: aquamarine;
    // gap: 5px;
    width: 150px;
    max-width: 150px;
    // max-width: 400px;
    max-height: 100px;
    min-height: 100px;
    // min-height: 600px;
    margin: auto;
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 5px;
  width: 25vw;
  max-width: 30vw;
  // max-width: 400px;
  max-height: 600px;
  min-height: 600px;
  // min-height: 600px;
  margin: auto;
  &.back-flip {
    .img-container {
      transform: rotateY(180deg);
    }
  }
  .slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .img-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: transparent;
    position: relative;
    width: 100%;
    min-width: 340px;
    max-width: 340px;
    max-height: 535px;
    min-height: 535px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    &.shake {
      animation: shake 1s infinite;
    }
    @keyframes shake {
      0% {
        transform: translate(2px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-2deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(3deg);
      }
      30% {
        transform: translate(0px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(2px, 1px) rotate(-2deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(4deg);
      }
      90% {
        transform: translate(2px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }
    .front,
    .back {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 340px;
      max-width: 400px;
      width: 100%;
      max-height: 530px;
      min-height: 530px;
      position: absolute;
      text-align: center;
      color: white;
      -webkit-perspective: 0;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      visibility: visible;
      backface-visibility: hidden;
      border-radius: 25px;
      img {
        width: 100%;
        max-height: 530px;
        min-height: 530px;
        border-radius: 25px;
      }
      .reveal {
        text-align: center;
        position: absolute;
        margin: auto;
        font-size: xx-large;
      }
      .unlock {
        text-align: center;
        position: absolute;
        margin: 100% auto auto auto;
        font-size: xx-large;
        button {
          font-size: 18px;
          min-width: 60px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: transparent;
          color: #fff;
          border-color: #fff;
          padding: 0 5px;
          height: 25px;
          border-style: solid;
          border-width: 1px;
        }
        input {
          height: 25px;
          max-width: 150px;
          font-size: 16px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-color: #fff;
          background-color: transparent;
          color: #fff;
          padding: 0 10px;
          border-style: solid;
          border-width: 1px;
          border-right-style: none;
        }
      }
      .date-reveal {
        margin-top: 30%;
        position: absolute;
        font-size: 20px;
        top: 85%;
        right: 10%;
        margin: auto;
        padding: 10px;
        border-width: 2px;
        border-style: solid;
        border-color: #fff;
        border-radius: 50px;
      }
      .previous-text {
        margin-top: 30%;
        position: absolute;
        font-size: 16px;
        font-weight: bolder;
        top: 75%;
        // right: 10%;
        padding: 10px;
        text-align: center;
        margin: auto;
      }
    }
    .front {
      .circle-container {
        padding-top: 5%;
        max-width: 250px;
        min-width: 150px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .circle-front {
          margin: auto;
          max-width: 150px;
          max-height: 150px;
          min-width: 150px;
          min-height: 150px;
          img {
            max-width: 130px;
            max-height: 130px;
            min-width: 130px;
            min-height: 130px;
            border-radius: 100%;
            cursor: pointer;
          }
          button {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border-color: transparent;
            background-color: transparent;

            span {
              padding: 5px;
              font-size: 18px;
              color: #fff;
              border-color: #fff;
              border-width: 1px;
              border-style: solid;
              border-radius: 50px;
            }
          }
        }
        .circle-text {
          p {
            color: #000;
            font-size: 14px;
            flex-wrap: nowrap !important;
            min-width: 200px;
          }
        }
      }
    }
    .back {
      transform: rotateY(180deg);
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-height: 550px;
        min-height: 550px;
        padding: 20px;
        background-color: var(--bg-card);
        border-radius: 20px;
        a {
          cursor: pointer;
          // width: 150px;
          height: 40px;
          font-size: 25px;
          border-style: solid;
          border-width: 3px;
          border-radius: 50px;
          margin-top: 50px;
          padding: 0 10px;
        }
        .text {
          overflow-y: auto;
          user-select: text;
          p {
            &.small {
              font-size: 16px !important;
            }
            &.medium {
              font-size: 18px !important;
            }
            word-wrap: break-word;
            font-family: Teko;
            text-align: center;
            min-height: 100%;
            font-size: 20px;
          }
        }
        .circle {
          max-width: 150px;
          max-height: 150px;
          min-width: 150px;
          min-height: 150px;
          img {
            max-width: 130px;
            max-height: 130px;
            min-width: 130px;
            min-height: 130px;
            border-radius: 100%;
            cursor: pointer;
          }
          button {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border-color: transparent;
            background-color: transparent;

            span {
              padding: 5px;
              font-size: 18px;
              color: #fff;
              border-color: #fff;
              border-width: 1px;
              border-style: solid;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
  .bar-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 150px;
    border-color: #fff;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    height: 20px;
    .progress {
      background-color: #fff;
      border-radius: 10px;
      height: 20px;
    }
  }
  .bar-empty {
    min-height: 50px;
  }
  .no-bar {
    min-height: 20px;
  }
  .clics {
    width: 50%;
    min-width: 150px;
    text-align: center;
  }
  .time {
    font-size: 20px;
    font-weight: 700;
    width: 50%;
    min-width: 150px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .card {
    .img-container {
      max-height: 500px;
      min-height: 500px;
      min-width: 300px;
      max-width: 300px;
      .front,
      .back {
        max-height: 490px;
        min-height: 490px;
        min-width: 300px;
        max-width: 300px;
        .previous-text {
          font-size: 14px;
        }
        img {
          max-height: 490px;
          min-height: 490px;
        }
      }
      .back {
        .content {
          .text {
            overflow-y: auto;
            user-select: text;
            p {
              &.small {
                font-size: 14px !important;
              }
              &.medium {
                font-size: 14px !important;
              }
              font-size: 18px;
            }
          }
          .circle {
            max-width: 100px;
            max-height: 100px;
            min-width: 100px;
            min-height: 100px;
            cursor: pointer;
            p {
              text-align: center;
              width: 100%;
              min-width: 200px;
            }
            img {
              max-width: 90px;
              max-height: 90px;
              min-width: 90px;
              min-height: 90px;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}
