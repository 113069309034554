.home {
  color: var(--text-main);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
  gap: 10px;
  .yield-title {
    width: 100%;
    h1 {
      text-align: left !important;
    }
  }
  .wallet-container {
    display: flex;
    justify-content: end;
    width: 100%;
    height: 10%;
    padding: 20px;
    margin: 20px;
  }
  .top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: 20%;
  }
  .body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 70%;
  }
}

@media screen and (max-width: 950px) {
  .home {
    .top-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 800px) {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 450px) {
  .home {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1224px) {
  .home {
    .wallet-container {
      padding: 0;
      font-size: small !important;
      margin: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    padding: 0;
  }
}
