.artists {
  background-color: var(--bg-secondary);
  margin-top: -6vh;

  input {
    display: none;
  }

  label {
    display: inline-block;
    margin: 0 0 -1px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
    font-size: calc(2vw + 1vh);
  }

  input:checked + label {
    color: #e26f2f;
    border: 1px solid #e26f2f;
    border-top: 2px solid #e26f2f;
    border-bottom: 1px solid black;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .artists-title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    text-align: left;
    margin-left: 10vw;
    label {
      cursor: pointer;
    }
    button {
      width: 40%;
      min-width: 2vw;
      height: calc(2vw + 3vh);
      background-color: transparent;
      color: #fff;
      border-style: solid;
      border-color: #fff;
      border-width: 2px;
      font-size: calc(2vw + 0.6vh);
      cursor: pointer;
      &.active {
        color: #e26f2f;
        border-color: #e26f2f;
      }
    }
  }
  .artists-container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #e26f2f;

    svg {
      color: #fff;
    }
    .filter {
      bottom: 0;
      width: 10vw;
      padding-top: 10px;
      .controls {
        display: flex;
        flex-direction: column;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        align-self: flex-start;
        button {
          background-color: transparent;
          color: #fff;
          border-style: none;
          font-size: large;
          cursor: pointer;
        }
        .active {
          color: #e26f2f;
        }
      }
    }
    .artists-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width: 100%;
        .input-container {
          display: flex;
          width: 25%;
          height: 40px;
          border-radius: 25px;
          border-style: solid;
          border-width: 1px;
          margin: 10px 0;
          svg {
            padding: 10px;
            color: white;
            min-width: 40px;
            min-height: 40px;
            text-align: center;
          }
          input {
            display: block;
            background-color: transparent;
            color: #fff;
            width: 100%;
            min-width: 200px;
            min-height: 40px;
            outline: none;
            border-style: none;
          }
        }
      }
      .artists-items {
        padding: 0.5rem;
        display: inline-flex;
        justify-content: center;
        align-content: flex-start;
        width: 90vw;
        height: 100vh;
        margin: 0;
        flex-wrap: wrap;
        overflow: auto;
        .item {
          width: 35%;
          max-width: 300px;
          min-width: 300px;
          min-height: 300px;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 2px;
          border-radius: 10px;
          gap: 10px;
          position: relative;
          .img-cover {
            width: 35%;
            max-width: 300px;
            min-width: 300px;
            min-height: 300px;
            padding: 0.5rem;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .culture-toggle-yes {
              width: 48px;
              height: 34px;
              position: absolute;
            }
            .social-links {
              display: flex;
              flex-direction: row;
              justify-self: end;
              align-self: flex-end;
              z-index: 10;
              font-size: 20px;
              margin-right: 20px;
              margin-top: -5px;
            }
            .culture {
              display: flex;
              flex-direction: column;
              justify-content: end;
              -webkit-justify-content: flex-end;
              align-items: flex-end;
              -webkit-align-items: flex-end;
              min-width: 100%;
              height: 100%;
              min-height: 250px;
              cursor: pointer;
              z-index: 5;

              .name {
                display: flex;
                flex-direction: row;
                justify-self: baseline;
                align-self: flex-start;
                z-index: 2;
                margin-left: 5px;
                font-size: 25px;
                font-weight: bold;
                width: 100%;
              }
            }
            .creators {
              display: flex;
              flex-direction: column;
              justify-content: end;
              -webkit-justify-content: flex-end;
              align-items: flex-end;
              -webkit-align-items: flex-end;
              min-width: 100%;
              height: 100%;
              min-height: 250px;
              z-index: 5;

              .name {
                display: flex;
                flex-direction: row;
                justify-self: baseline;
                align-self: flex-start;
                z-index: 2;
                margin-left: 5px;
                font-size: 25px;
                font-weight: bold;
                width: 100%;
              }
            }
          }
          img {
            position: relative;
            width: 100%;
            height: 300px;
            border-radius: 10px;
          }
        }

        .item::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('./../../../assets/shadow.png'); /* Path to your overlay image */
          background-size: cover; /* Adjust the sizing of the overlay image */
          opacity: 0.5; /* Set the desired opacity value (0 to 1) */
          border-radius: 10px; /* Apply the same border radius as the img tag */
          z-index: 1; /* Ensure the overlay image is on top of the img tag */
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .artists {
    margin-top: -4vh;
    .artists-container {
      .artists-section {
        .search {
          .input-container {
            width: 55%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .artists {
    margin-top: -4vh;
    .artists-container {
      .filter {
        .controls {
          top: 80px;
          button {
            font-size: 13px;
          }
        }
      }
      .artists-section {
        .search {
          .input-container {
            width: 65%;
          }
        }
      }
    }
  }
}
