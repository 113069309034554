@font-face {
  font-family: Teko;
  src: url('../fonts/Teko/Teko-Regular.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Sofia Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#page-wrap {
  min-height: 100vh;
  margin-top: -20vh;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0) !important;
}

body {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: #000;
  background-size: cover;
  background-repeat: no-repeat;
  // padding-bottom: 5rem;
}

.show {
  display: block !important;
}

.hidden {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.bm-item {
  list-style: none;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: var(--text-primary);
  transition: color 0.2s;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  border-radius: 10px;
  &:hover {
    background-color: #373a47;
  }
}

.sidebar {
  cursor: auto;
  color: #fff;
  z-index: 5;

  &:hover {
    background-color: transparent;
  }
  ul {
    padding: 0 !important;
    li {
      padding: 10px 5px;
      cursor: pointer;
      color: #fff;

      .menu {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        gap: 5px;
        width: 100%;
        height: 40px;
        max-height: 40px;
        .selected {
          font-size: 20px;
          align-items: baseline;
          justify-content: baseline;
          color: #fff;
          text-decoration: underline;
        }
        a {
          font-size: 25px;
          color: #fff;
          text-decoration: none;
          align-items: left;
          .link {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            small {
              font-size: 20px;
            }
            img {
              width: 28px;
              height: 18px;
              margin: 0 15px;
            }
          }
        }
      }

      .opensea {
        margin-left: -1.5vw;

      }

      .languageSelector {
        margin-left: -1.5vw;
      }

      cursor: pointer;
      user-select: none;

      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left;
        text-decoration: none;
        color: #fff;
      }

      &:hover {
        background-color: #000;
      }
      svg {
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      #discord {
        fill: #5165f6;
      }

      #youtube {
        fill: #f71801;
      }

      #twitter {
        fill: #3da1f2;
      }

      display: flex;
      align-items: left;
      gap: 10px;

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  .wallet-container {
    width: 100%;
  }
}

.bm-item:hover {
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #fff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #fff;
}

.bm-menu-wrap {
  top: 0 !important;
  max-width: 300px !important;
}
.bm-menu {
  background: #000;
  padding: 2.5em 10px;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 375px) {
  .bm-burger-button {
    position: fixed;
    width: 31px;
    height: 30px;
    right: 10px;
    top: 25px;
  }
}

@media screen and (max-width: 800px) {
  body {
    width: 100%;
    height: 100%;
  }
}
