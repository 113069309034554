.main {
  padding: 5% 0 0 0;
  width: 100%;
  height: 90vh;
}
@media screen and (max-width: 500px) {
  .main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0;
    .slick-slider {
      width: 80%;
      .slick-slide {
        padding: 0 5px;
      }
    }
  }
}
