html, body{
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
}

.home {
  height: 100%;
  color: var(--text-main);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
  gap: 10px;
  .yield-title {
    width: 100%;
    h1 {
      text-align: left !important;
    }
  }
  .wallet-container {
    display: flex;
    justify-content: end;
    width: 100%;
    height: 10%;
    padding: 20px;
    margin: 20px;
  }
  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: 20%;
  }
  .body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 70%;
  }
}

#section-container{
  height: 100vh;
}

section {
  scroll-snap-align: start;
  min-height: 115vh;
  padding-top: 15vh;
}

#footer {
  scroll-snap-align: start;
  height: 0.5vh;
}

#footer{
  scroll-snap-align: start;
}

#title{
  padding-top: calc(30vh - 10vw);
  font-size: 12vw;
  font-weight: bold;
}

#subtitle{
  font-size: calc(3vw + 1vh);
  color: #858383;
  margin-top: -1.5vh;
}

#section-title{
  font-size: calc(2vw + 3vh);
  line-height: calc(2vw + 3vh);
  margin-left: 4%;
  margin-right: 4%;
}

#section-subtitle{
  font-size: calc(1.5vw + 1.5vh);
  margin-bottom: -1.5vh;
  color: #858383;
  padding-left: 4%;
}

#join-us{
  font-size: calc(2vw + 2vh);
  margin-bottom: -1.5vh;
  color: #858383;
  padding-left: 4%;
  font-weight: bold;
}

#section-text{
  font-size: calc(1vw + 2vh);
  line-height: calc(1vw + 2vh);
  padding-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}

#section-text2{
  font-size: calc(1vw + 2vh);
  line-height: calc(1vw + 2vh);
  margin-left: 8%;
  margin-right: 8%;
}

#lets{
  font-size: calc(1vw + 2vh);
  line-height: calc(1vw + 2vh);
  margin-left: 6%;
  margin-top: 2%;
}

.border-right {
  border-right: 1px solid #ddd;
}

.border-left {
  border-left: 1px solid #ddd;
  padding-left: 10px;
}

.orange{
  color: #e26f2f;
}

.gray{
  color: #858383;
}

#monters-image{
  flex-basis: 50%;
  max-height: 40vh;
}

#monsters{
  font-size: calc(1vw + 3vh);
  line-height: calc(1vw + 3vh);
  text-align: left;
  padding: 2%;
  border-style: solid;
  border-color: #2B1875;
  border-width: 1px;
  flex-basis: 50%;
  height: 40vh;
}

#monster-section{
  display: flex;
  //margin-bottom: 20vh;
  padding-top: 30vh;
}

.play-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto; /* Center the button horizontally */
  padding: 0;
  width: 15%;
  margin-left: 40%;
  margin-top: 5%;
}

.play-button img {
  width: 100%;
  height: 100%;
}

.video-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 6%;
  border-radius: 4px;
  outline: none;
  width: 140vh;
  max-width: 90vw;
  height: 90vh;
  z-index: 20;
}

.video-modal video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 5%;
  right: 10%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
}


@media screen and (max-width: 950px) {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-direction: column;
    padding: 0 10%;
  }

  #monster-section {
    flex-direction: column;
    justify-content: center; 
    flex-wrap: wrap;
    margin-bottom: none;
  }

  #monters-image,
  #monsters {
    max-width: 60%;
    justify-content: center;
    font-size: calc(1vw + 2vh);
    line-height: calc(1vw + 2vh);
    max-height: none;
    text-align: center;
  }

  #monters-image{
    margin-top: -40vh;
  }

  #monters-image img{
    max-width: 50vh;
  }

  #monsters{
    margin-left: 20%;
  }

  .video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5%;
    padding-top: 15%;
    border-radius: 4px;
    outline: none;
    width: 100%;
    height: 100%;
    z-index: 20;
  }

  .close-button {
    position: absolute;
    top: 5%;
    right: 85%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
  }
  
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #section-joinus2{
    height: 145vh;
  }

  #section-text2{
    font-size: calc(1vw + 2vh);
    line-height: calc(1vw + 2vh);
    padding-top: 5%;
    margin-left: 8%;
    margin-right: 8%;
  }

}

@media screen and (max-width: 1224px) {
  .home {
    .wallet-container {
      padding: 0;
      font-size: small !important;
      margin: auto;
    }
  }
}

#main-section{
  padding-top: 5vh;
  min-height: 100vh;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

span {
  opacity: 0;
  filter: blur(4px);
  padding-right: 0.5vw;
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
