.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  &--background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(25, 32, 57, 0.55) !important;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &--container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 1rem;
    z-index: 4;
    .modal--container--close-ico {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 4;
      path {
        stroke: var(--text-main-color);
      }
    }

    &--body {
      z-index: 3;
      position: relative;
    }
  }
}
